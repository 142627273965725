import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "rodic_signs_with_riga" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Тренер по Физической подготовке Никола Родич из Северной Македонии подписал
контракт с ФК Рига на один год. Он будет работать в качестве главного тренера по физической
подготовке всех возрастов в академии ФК Рига.</p>
        </div>
      </div>
    )
  }
}